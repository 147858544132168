<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="store" :query="{}">
      <DefaultBtn
        v-show="validateActionsPage('store-add-providers')"
        titleButton="Novo Fornecedor"
        icon="add"
        route="new-provider-product"
      />
    </Mural>

    <main class="lesson-content q-my-lg">
      <div class="content-table row flex q-pt-lg">
        <div class="column col-12">
          <Table
            class="full-height"
            :list="providers"
            :columns="columnsTable"
            :addRemove="validateActionsPage('store-edit-providers')"
            :addEdit="validateActionsPage('store-edit-providers')"
            @edit="(item) => edit(item)"
            ><template v-slot:body="props">
              <div>
                <q-btn
                  v-if="validateActionsPage('store-edit-providers')"
                  dense
                  round
                  color="default-pink"
                  title="Editar"
                  flat
                  @click="() => edit(props.props.row)"
                  icon="o_edit_note"
                />
                <q-btn
                  v-if="validateActionsPage('store-edit-providers')"
                  dense
                  round
                  color="default-pink"
                  title="Remover"
                  flat
                  @click="() => handleDelete(props.props.row)"
                  icon="delete"
                />
              </div>
            </template>
          </Table>
        </div>
      </div>
    </main>
    <q-inner-loading
      :showing="loadingPage"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import WareHouseService from "@/services/WareHouseService";
import Table from "@/components/shared/tables/TableDynamic.vue";

import CanUserAccessClass from "@/utils/CanUserAccessClass";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

import { ref, onMounted } from "vue";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";

export default {
  name: "ProviderProduct",
  components: {
    Mural,
    DefaultBtn,
    Table,
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let breadcrumbs = ref(["Fornecedores"]);
    let providers = ref([]);
    let $q = new useQuasar();
    let router = new useRouter();
    let loadingPage = ref(false);

    let canUserAccessClass = new CanUserAccessClass();
    const alertsClass = new AlertsClass();

    let columnsTable = ref([
      { name: "id", align: "left", label: "ID", field: "id", sortable: false },
      {
        name: "name",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.name,
        sortable: true,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
        sortable: false,
      },
    ]);

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    function edit(provider) {
      router.push({
        name: "new-provider-product",
        query: { providerId: provider.id },
      });
    }

    function handleDelete(provider) {
      const { id, name } = provider;

      $q.notify({
        message: `Deseja remover o fornecedor <strong>${name}</strong>?`,
        html: true,
        type: "warning",
        position: "top",
        actions: [
          {
            label: "Remover",
            color: "black",
            handler: async () => {
              await _wareHouseService
                .disableProvider(id)
                .then((response) => {
                  if (!response.data) {
                    $q.notify({
                      message: `Erro ou tentar remover o fornecedor <strong>${name}</strong>!`,
                      html: true,
                      type: "negative",
                      position: "top",
                    });
                    return;
                  }
                  providers.value = [];
                  _getAllProviderProduct();
                })
                .catch((error) => {
                  $q.notify({
                    message: `Erro ou tentar remover o fornecedor <strong>${name}</strong>!`,
                    caption: "",
                    html: true,
                    type: "negative",
                    position: "top",
                  });
                });
            },
          },
        ],
      });
    }

    async function _getAllProviderProduct() {
      loadingPage.value = true;
      let data = await _wareHouseService.getAllProviders();
      providers.value = data.data;
      loadingPage.value = false;
    }

    onMounted(() => {
      _getAllProviderProduct();
    });
    return {
      breadcrumbs,
      providers,
      loadingPage,
      columnsTable,
      edit,
      validateActionsPage,
      handleDelete,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}
.content-card {
  width: 100%;
  height: 206px;
  background: #f7f7f7;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}

.content {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.07em;
  color: #9c9c9c;

  .bold_number {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000 !important;
  }
}

.image-groups {
  border-radius: 20px;
}

.card-btn {
  box-shadow: 3px 10px 15px #00000033;
  width: 100px;
}
</style>
